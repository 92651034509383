import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        mobileMenu: {
            is_mobile: false,
            is_expanded: false,
            is_extra_small: false
        },
    },
    mutations: {
        updateMobileMenu(state, payload) {
            state.mobileMenu.is_mobile = payload.is_mobile,
            state.mobileMenu.is_expanded = payload.is_expanded
            state.mobileMenu.is_extra_small = payload.is_extra_small
        },
    },
    actions: {
        setMobileMenu: async ({ commit }) => {
            let screenWidth = window.innerWidth
            let smallMenu = false
            let extra_small = false
            let menuExpanded

            if (screenWidth <= 1400){
                smallMenu = true;
                if (screenWidth <= 700){
                    extra_small = true
                } else {
                    extra_small = false
                }
            } else {
                smallMenu = false
                extra_small = false
            }

            if (!smallMenu){
                menuExpanded = false
                extra_small = false
            }

            let payload = {
                is_mobile: smallMenu,
                is_expanded: menuExpanded,
                is_extra_small: extra_small
            }

            await commit('updateMobileMenu', payload)

        },
    },
    modules: {
    }
})

<template>
    <nav class="flex flex-col h-screen py-4 overflow-y-auto overflow-x-hidden">
        <main class="mb-auto h-10 pb-4">
            <div class="mx-auto">
                <div class="absolute top-5 left-1">
                    <button 
                    @click="closeMobileMenu" 
                    class="block text-black focus:outline-none" 
                    v-if="mobileMenu.is_mobile">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                        class="h-8 w-8 fill-current"
                    >
                        <path v-if="mobileMenu.is_expanded" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />

                    </svg>
                </button>
                   
                </div>
                <div class="flex justify-center">
                    <Logo :iconOnly="true"/>
                </div>
            </div>
            <div class="text-center py-1 px-4">
                <h1 class="block text-2xl 3xl:text-3xl font-medium">
                    SPENCER BRUTUS
                </h1>
                <p class="text-xs pb-2">
                    MUSICIAN . PRODUCER . COMPOSER
                </p>
                <div class="py-2 flex justify-center">
                    <a 
                        href="https://www.facebook.com/spencerbrutus88/"
                        target="_blank"
                        class="hover:text-blue-600 text-3xl px-1 cursor-pointer">
                         <i class="fab fa-facebook"></i>
                    </a>

                    <a 
                        href="https://www.instagram.com/spenbrut88/"
                        target="_blank"
                        class="hover:text-pink-500 text-3xl px-1 cursor-pointer">
                        <i class="fab fa-instagram"></i>

                    </a>
                    <a 
                        href="https://open.spotify.com/artist/6GOXUw6yJCDceVTpenyLVC"
                        target="_blank"
                        class="hover:text-green-500 text-3xl px-1 cursor-pointer">
                        <i class="fab fa-spotify"></i>

                    </a>
                    <a 
                        href="https://www.youtube.com/user/maestropen88"
                        target="_blank"
                        class="hover:text-red-600 text-3xl px-1 cursor-pointer">
                        <i class="fab fa-youtube"></i>

                    </a>
                    <a 
                        href="https://itunes.apple.com/us/artist/spencer-brutus/945926658"
                        target="_blank"
                        class="hover:text-pink-600 text-3xl px-1 cursor-pointer">
                        <i class="fab fa-itunes"></i>
                    </a>
                </div>
            </div>
            <div class="py-0 border-b-1 pb-2 border-gray-60 xs:mt-4">
                <div class="flex justify-center">
                    <span class="font-semibold">
                        Contact Me
                    </span>
                </div>
                <a class="flex justify-center text-xs xs:text-base" href="mailto:spencerbrutus@gmail.com">
                    spencerbrutus@gmail.com
                </a>
                <a class="flex justify-center text-xs xs:text-base" href="mailto:spencerbrutus@gmail.com">
                    (904) 627-0670
                </a>

            </div>

            <div class="pt-1 px-1 text-base flex justify-center mt-10">
                <div>
                    <h3 class=" font-semibold text-center text-sm">
                        WAYS TO PAY
                    </h3>
                    <div class="text-center my-2 left-20">
                        <div class="flex-col">
                            <img 
                                src="@/assets/images/zelle.svg" 
                                alt="Zelle"
                                class="inline-flex h-11 w-11 text-center"
                            >
                        </div>
                        <div class="flex-col">
                            <span>
                                spencerbrutus@gmail.com
                            </span>
                        </div>
                        
                    </div>
                    <div class=" text-center my-2 left-20">
                        <div class="flex-col">
                            <img 
                                src="@/assets/images/cashapp.svg" 
                                alt="Zelle"
                                class="inline-flex h-9 w-9 text-center"
                            >
                        </div>
                        <div class="flex-col">
                            <span>
                                $spencerbrutus88
                            </span>
                        </div>
                            
                    </div>
                    
                </div>
            </div>
        </main>

        <footer class="flex flex-col h-10 text-xs 3xl:text-sm border-t-1 px-4">
            <div class="my-auto">
                &copy; Copyright {{year}} Spencer Brutus
            </div>
        </footer>
    </nav>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Logo from '@/components/Logo'
export default {
    name: 'SideNav',

    components: {
        Logo
    },

    computed: {
        mobileMenu(){
            return this.$store.state.mobileMenu;
        },
    },

    data(){
        return {
            year: new Date().getFullYear()
        }
    },

    methods: {
        
        closeMobileMenu(){
            this.updateMobileMenu({
                is_mobile: !this.mobileMenu.smallMenu,
                is_expanded: false,
                is_extra_small: !this.mobileMenu.extra_small
            })

        },


        ...mapMutations({
            updateMobileMenu: 'updateMobileMenu'
        }),
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="bg-cover bg-center bg-no-repeat h-screen w-full hero">
        <div class="bg-black h-screen opacity-80 flex justify-center xl:justify-start">
            <div class="py-4 px-2 text-white my-auto w-11/12  xl:ml-48 3xl:max-w-72 relative">
                <div class="relative">
                    <div class="flex">
                        <p class="text-lg">
                            NOW OFFERING 
                        </p>
                    </div>
                    <h2 class="text-5xl md:text-8xl">
                        Piano Lessons
                    </h2>
                    <p class="text-base bg-white text-black rounded inline-flex px-4 mt-1">
                        ONLINE
                    </p>
                </div>
                <div class="flex justif-around mt-6 xl:mt-2">
                    <button
                        @click="initCalendar()"
                        type="button"
                        class="bg-green-500 text-white rounded-lg px-2 xs:px-4 py-4 3xl:px-10 w-full md:w-72 mt-10 text-sm xs:text-lg 3xl:text-xl focus:outline-none hover:bg-green-600">
                        <span>
                            BOOK NOW - $60/hr
                        </span>
                    </button>
                    <a
                        href="#piano-lessons"
                        class="ml-3 3xl:ml-8 bg-white text-black rounded-lg px-2 xs:px-4 xs:text-lg py-4 text-sm 3xl:px-10 w-full md:w-72 mt-10 3xl:text-xl focus:outline-none hover:bg-gray-200 text-center">
                        <span>
                            LEARN MORE
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeroSection',

    methods: {
        initCalendar(){
            Calendly.initPopupWidget({
                url: 'https://calendly.com/spencerbrutus/1-hour-piano-lesson'
            })
            return false
        }
    }
}
</script>

<style scoped>
    .hero{
        max-width:100vw; 
        background-image: url('../assets/images/Keys_5.jpg')
    }
</style>
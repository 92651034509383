<template>
    <div class="">
        <div class="px-6 xl:px-48 bg-gray-50 py-20">
            <h2 class="text-xl xs:text-2xl 3xl:text-4xl text-center xl:text-left mb-16 font-semibold uppercase">
                Online Piano Lessons
            </h2>
            <div class="md:grid md:gap-20 md:grid-cols-2">
                <div>
                    <p class="py-2 text-md">
                        I'm now offering piano lessons remotely via <strong>Zoom</strong>.
                        No matter where you are, I can help you on your journey to master the Piano.
                        <br><br>
                        I'm available for one hour lessons Monday - Friday from <strong>2PM</strong> EST to <strong>10PM</strong> EST.
                        You can book a one hour lesson within that time frame by clicking the button below.
                    </p>
                </div>
                <div>
                    <p class="py-2 text-md">
                       I will be teaching 3 different levels of piano, and my schedule is as follows: <br>
                       <ul>
                            <li class="py-2 flex">
                                <span>
                                    <i class="fas fa-caret-right"></i>
                                </span>
                                
                                <div class="pl-2">
                                    From <strong>2PM</strong> to <strong>4PM</strong> I will be teaching <em><strong>Beginner Level</strong></em> students.
                                </div>
                                
                            </li>
                            <li class="py-2 flex">
                                <i class="fas fa-caret-right"></i>
                                <div class="pl-2">
                                    From <strong>4PM</strong> to <strong>6PM</strong>, I will be teaching <em><strong>Intermediate Level</strong></em> students.
                                </div>
                               
                            </li>
                            <li class="py-2 flex">
                                <i class="fas fa-caret-right"></i>
                                <div class="pl-2">
                                    From <strong>7PM</strong> to <strong>10PM</strong>, I will be teaching <em><strong>Advanced Level</strong></em> students.
                                </div>
                            </li>
                       </ul>
                    </p>
                </div>
            </div>
            
            <div class="pt-10"
                :class="mobileMenu.is_expanded ? 'flex': 'block'">
                <div class="pt-1 px-1 text-base flex justify-center py-4 xl:hidden"
                :class="{'hidden':mobileMenu.is_expanded,  'block': !mobileMenu.is_expanded}">
                    <div>
                        <h3 class=" font-semibold text-center text-sm">
                            WAYS TO PAY
                        </h3>
                        <div class="text-center my-2 left-20">
                            <div class="flex-col">
                                <img 
                                    src="@/assets/images/zelle.svg" 
                                    alt="Zelle"
                                    class="inline-flex h-11 w-11 text-center"
                                >
                            </div>
                            <div class="flex-col">
                                <span>
                                    spencerbrutus@gmail.com
                                </span>
                            </div>
                            
                        </div>
                        <div class=" text-center my-2 left-20">
                            <div class="flex-col">
                                <img 
                                    src="@/assets/images/cashapp.svg" 
                                    alt="Zelle"
                                    class="inline-flex h-9 w-9 text-center"
                                >
                            </div>
                            <div class="flex-col">
                                <span>
                                    $spencerbrutus88
                                </span>
                            </div>
                                
                        </div>
                        
                    </div>
                </div>
                <div class="my-auto md:ml-4 xl:ml-0 text-center xl:text-left"
                    :class="mobileMenu.is_expanded ? 'ml-0' : 'md:ml-4'">
                    <button
                        @click="initCalendar()"
                        type="button"
                        class="bg-green-500 text-white rounded-lg px-10 py-4 mt-0 text-lg xl:text-xl focus:outline-none hover:bg-green-600">
                        <span>
                            BOOK A LESSON NOW - $60/hr
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PianoLessons',

    computed: {
        mobileMenu(){
            return this.$store.state.mobileMenu;
        },
    },

    methods: {
        initCalendar(){
            Calendly.initPopupWidget({
                url: 'https://calendly.com/spencerbrutus/1-hour-piano-lesson'
            })
            return false
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="h-screen grid grid-cols-8 overflow-x-hidden">
        <!-- Side Nav -->
        <aside 
            class="right-0 xl:left-0 col-span-1 shadow-md xl:shadow-none bg-white border-gray-200 fixed z-30 xl:z-10 transition-all duration-500 eas-in-out overflow-y-auto"
            :class="mobileMenu.is_expanded ? 'w-60 border-l-0' : mobileMenu.is_mobile ? 'w-0 border-l-0' : 'w-60 border-r-2 3xl:w-72'">
            <SideNav />
        </aside>
        <!-- Main content -->
        <main 
            class="relative w-full min-h-screen transition-all duration-500 eas-in-out bg-white"
            :class="mobileMenu.is_expanded ? 'col-span-8' : mobileMenu.is_mobile ? 'mr-0 col-span-8' : 'col-span-7 ml-60 3xl:ml-72'">
            <!-- Top Bar -->
            <header 
                class="w-full h-18 z-20 fixed bg-black"
                :class="mobileMenu.is_mobile ? 'block w-full' : 'lg:hidden'">
                <TopBar />
            </header>
            <section class="h-screen">
                <HeroSection />
            </section>
            <section class="bg-gray-50" id="piano-lessons">
                <PianoLessons />
            </section>
        </main>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import Logo from '@/components/Logo'
    import SideNav from '@/components/SideNav'
    import TopBar from '@/components/TopBar'
    import HeroSection from '@/components/HeroSection'
    import PianoLessons from '@/components/PianoLessons'
    export default {
        name: 'Home',
        components: {
            Logo,
            SideNav,
            TopBar,
            HeroSection,
            PianoLessons
        },

        computed: {
            mobileMenu(){
                return this.$store.state.mobileMenu;
            },
        },

        data(){
            return {
                // mobileMenu: {
                //     is_mobile: false,
                //     is_expanded: false,
                //     is_extra_small: false
                // },
            }
        }
    }
</script>

<template>
    <section class="h-full">
        <div class="flex items-center justify-between px-6 h-full my-2">
            <div>
                <span>
                    <img 
                        src="@/assets/images/logo-sm-white.png" 
                        alt="Spencer Brutus"
                        class="w-48"
                    >
                </span>
            </div>
            <div class="flex">
                <button 
                    @click="openMobileMenu" 
                    class="block text-white focus:outline-none dark:text-gray-300 dark-hover:text-white" 
                    v-if="mobileMenu.is_mobile">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                        class="h-8 w-8 fill-current"
                    >   
                        <path v-if="!mobileMenu.is_expanded" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
                    </svg>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Logo from '@/components/Logo'
export default {
    name: 'TopBar',

    components: {
        Logo
    },

    computed: {
        mobileMenu(){
            return this.$store.state.mobileMenu;
        },
    },

    data(){
        return {
           
        }
    },


    mounted(){
        this.getScreenWidth()
    },
    
    methods: {
        getScreenWidth(){
            let vm = this
            vm.setMobileMenu()
            
            window.addEventListener('resize', function(){
                vm.setMobileMenu()
            });
                      
        },

        openMobileMenu(){
            this.updateMobileMenu({
                is_mobile: !this.mobileMenu.smallMenu,
                is_expanded: true,
                is_extra_small: !this.mobileMenu.extra_small
            })
        },

        ...mapMutations({
            updateMobileMenu: 'updateMobileMenu'
        }),
        ...mapActions(['setMobileMenu'])
    }
}
</script>

<style scoped>

</style>
<template>
    <div>
        <img
            v-if="!iconOnly && variant === 'black'"
            src="@/assets/images/Logo-black.png" 
            alt="Spencer Brutus"
            class="w-36"
            >
        <img
            v-if="iconOnly && variant === 'black'"
            src="@/assets/images/Logo-icon-black.png" 
            alt="Spencer Brutus"
            class="w-36" 
            >
        <img
            v-if="!iconOnly && variant === 'white'"
            src="@/assets/images/Logo-white.png" 
            alt="Spencer Brutus"
            class="w-36"
            >
        <img
            v-if="iconOnly && variant === 'white'"
            src="@/assets/images/Logo-icon-white.png" 
            alt="Spencer Brutus"
            class="w-36"
            >
    </div>
</template>

<script>
export default {
    name: 'Logo',
    props: {
        variant: {
            type: String,
            default: 'black'
        },

        iconOnly: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>

</style>